@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&family=Poppins:ital,wght@0,400;0,500;0,600;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

body {
  margin: 0;
  font-family: "Fredoka", sans-serif;
}
.hScroll::-webkit-scrollbar {
  /* display: none; */
  scrollbar-width: 2px !important;
}
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
:root {
  --doctor-color: rgba(1, 104, 85, 1);
  --dark-primary: #01483b;
  --dark-secondary: #207a69;
}
.relative13::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid transparent; /* Initial transparent border */
  border-radius: 100%; /* Match with inner div rounded corners */
  background: linear-gradient(270deg, #baedbd 20%, rgba(243, 78, 78, 0.4) 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude; /* Mask the center so only the border shows */
  padding: 2px; /* Adjust to simulate border width */
  z-index: -1;
}

.relative2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid transparent; /* Initial transparent border */
  border-radius: 100%; /* Match with inner div rounded corners */
  background: linear-gradient(
    270deg,
    #ff5656 20%,
    rgba(149, 164, 252, 0.2) 100%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude; /* Mask the center so only the border shows */
  padding: 2px; /* Adjust to simulate border width */
  z-index: -1;
}


.relative3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid transparent; /* Initial transparent border */
  border-radius: 100%; /* Match with inner div rounded corners */
  background: linear-gradient(270deg, #95a4fc 20%, rgba(42, 101, 85, 0.2) 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude; /* Mask the center so only the border shows */
  padding: 2px; /* Adjust to simulate border width */
  z-index: -1;
}

.custom-dots li.slick-active div {
  background-color: #2a6555 !important; /* Change this to your desired active color */
}
.gradientRelative::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid transparent; /* Initial transparent border */
  border-radius: 100%; /* Match with inner div rounded corners */
  background: linear-gradient(270deg, #F34E4E 0%, #BAEDBD 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude; /* Mask the center so only the border shows */
  padding: 1px; /* Adjust to simulate border width */
  z-index: -1;
}




