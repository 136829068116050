@import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&family=Poppins:ital,wght@0,400;0,500;0,600;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shalimar&display=swap");
.lastBorderr:last-child {
  border-bottom: none !important;
}
.lightmode {
  background-color: #eaf0ee;
  color: #282828;
}
.darkmode {
  background-color: #373737;
  color: #fff;
}
.slickttt {
  width: 60px;
  height: 30px;
  background: #000;
}

.notyInput {
  width: 25px;
  height: 25px;
  border-radius: 5px;
  border: none;
  outline: none;
  accent-color: var(--doctor-color);
}
.tableInput {
  width: 15px;
  height: 15px;
  border-radius: 5px;
  border: none;
  outline: none;
  accent-color: var(--doctor-color);
}
.css-6cwnna-MuiSlider-mark {
  width: 15px !important;
  height: 15px !important;
  margin-left: -5px !important;
  border-radius: 50% !important;
  background-color: #67a599 !important ;
}

.MuiSlider-mark {
  width: 15px !important;
  height: 15px !important;
  border-radius: 50% !important;
  margin-left: -10px !important;

  background-color: #67a599 !important ;
}

.MuiSlider-valueLabel {
  display: none !important;
}
.MuiSlider-markLabelActive {
  color: red !important;
}
.MuiSlider-markLabel {
  margin-top: -50px !important;
  color: rgba(0, 0, 0, 0.4) !important;
}
.resourceBackground {
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.75) 100%,
      rgba(0, 0, 0, 0.75) 0%
    ),
    url("../src/images//resource1.png");

  background-position: center center;
  -moz-border-radius: 5px !important;
  border-radius: 5px !important;
}

.toggle-slider {
  position: relative;
  width: 60px;
  height: 36px;
}

.slider {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border-radius: 34px;
  cursor: pointer;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 2px;
  bottom: 2px;
  background-color: var(--doctor-color);
  border-radius: 50%;
  transition: 0.4s;
}

.inputCheck {
  display: none;
}

.inputCheck:checked + .slider {
  background-color: white;
}

.inputCheck:checked + .slider:before {
  transform: translateX(35px);
}
.inputChecktwo {
  display: none;
}

.inputChecktwo:checked + .slider {
  background-color: #dca071;
}

.inputChecktwo:checked + .slider:before {
  transform: translateX(35px);
}

.notyNotch {
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 30px solid white;
}

.round {
  position: relative;
}

.round label {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  height: 15px;
  left: 0;
  position: absolute;
  top: 0;
  width: 15px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 4px;
  left: 3px;
  opacity: 0;
  position: absolute;
  top: 4px;
  transform: rotate(-45deg);
  width: 8px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: var(--doctor-color);
  border-color: var(--doctor-color);
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
.cutomFbBtn {
  background-color: #ffffff;
  width: 50px;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #18acfe;
  border-radius: 10px;
}

.reactToggleCss {
  vertical-align: middle;
  margin-left: 4px;
  background: red !important;
}
.custom-width {
  width: calc(100% - 245px);
}

.carousel-card {
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0.5; /* Default opacity for inactive slides */
  transform: scale(0.9); /* Slightly shrink inactive slides */
  border-radius: 20px;
  overflow: hidden;
}

/* Highlight the active center slide */
.slick-center .slickOver {
  border-radius: 20px !important;
  overflow: hidden !important;
}
.slick-center .carousel-card {
  transform: scale(1.1); /* Slightly zoom in the active center slide */
  opacity: 1; /* Full opacity for the active slide */
  /* Add shadow for emphasis */
  border-radius: 20px;
  overflow: hidden;
  padding-bottom: 20px;
  z-index: 10; /* Bring the center slide to the top */
  /* border-top-left-radius: 20px;
  border-top-right-radius: 20px; */
}

/* Adjust the overall carousel container */
.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-prev,
.slick-next {
  z-index: 20;
}

.slick-prev:before,
.slick-next:before {
  color: #000; /* Adjust arrow color */
}

.logo-scroll {
  animation: scroll 14s linear infinite;
}
@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
